import React, { useState } from 'react'
import Layout from '../components/layout';
import Seo from "../components/SEO/SEO"
import HeaderPaginas from "../components/headerPaginas"
import "../styles/contacto.scss"
import "../styles/empleo.scss"
import TextareaAutosize from 'react-textarea-autosize';
import { IconContext } from "react-icons";
import { GrFormTrash } from 'react-icons/gr';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link } from 'gatsby';


const Empleo = (props) => {

    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [CV, setCV] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const [respuesta, setRespuesta] = useState("");
    const [valido, setValido] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha()

    const validarArchivo = (e) => {
        const file = e.target.files[0]
        // file = e.target.files[0];
        console.log(file)
        const divMsg = document.getElementById('mensaje-archivo');

        if (!file) {
            // if (file.length === 0) {
            divMsg.textContent = 'Por favor adjunta un archivo'
            divMsg.style.background = "darkred"
            divMsg.style.color = "white"
            setValido(false)
        } else {
            if (/\.(doc|docx|pdf|odt)$/i.test(file.name) === false) {
                // alert("Formato no valido!");
                divMsg.textContent = 'Formato de archivo no válido'
                divMsg.style.background = "darkred"
                divMsg.style.color = "white"
                setValido(false)
            } else {
                divMsg.textContent = file.name
                divMsg.style.background = "lightgreen"
                divMsg.style.color = "#1f1f1f"
                setValido(true)
                // setCV(file)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // Check if the captcha was skipped or not
        if (!executeRecaptcha) {
            return
        }

        // This is the same as grecaptcha.execute on traditional html script tags
        const result = await executeRecaptcha('contacto')
        //--> grab the generated token by the reCAPTCHA
        // Prepare the data for the server, specifically body-parser
        // const formData = new FormData();
        // const data = JSON.stringify({ nombre, email, telefono, mensaje, result })
        // formData.append("files.curriculum", CV);
        // formData.append('data', data);

        const formData = new FormData();
        const data = {}
        let archivo = ""
        data["Nombre"] = nombre
        data["Email"] = email
        data["Mensaje"] = mensaje
        data["Telefono"] = telefono
        data["result"] = result

        if (valido) {
            archivo = document.getElementById('curriculum').files[0]
        }

        formData.append("files.curriculum", archivo);
        formData.append('data', JSON.stringify(data));

        // POST request to your server
        fetch(`${process.env.API_URL}/cvsecurities`, {
            method: 'POST',
            body: formData //--> this contains your data
        })
            .then(res => res.json())
            .then(data => {
                const success = data.success
                const score = data.score

                if (success && score >= 0.7) {
                    setMensajeError("Gracias por ponerte en contacto con nosotros")
                    setRespuesta("correcto");
                    setNombre("")
                    setEmail("")
                    setTelefono("")
                    setCV("")
                    setMensaje("")
                    setIsChecked(false)
                } else {
                    setMensajeError("Ha habido un error en el envío del formulario, por favor inténtalo de nuevo")
                    setRespuesta("error")
                }
            })
    }

    return (
        <div>
            <Layout>
                <Seo title="Empleo" pathname={props.location.pathname} robots="noindex,follow" />
                <HeaderPaginas titulo="Empleo" imagen="/img/cabeceras/hombre-gracioso-corriendo-amarillo-1920.jpg" />

                <div className="cuerpo-contacto cuerpo-empleo limitador">
                    <p className="parrafo-destacado">¿Demasiado tiempo quieto? ¿en el mismo sitio? ¿formándote? Ha llegado el momento de moverte y enviarnos tu curriculum porque queremos conocer personas que se quieran unir a un equipo top en sistemas y en ciberseguridad. Gente sin complejos y con muchas ganas.
                        <br />No te cortes. Vamos a cuidar muy bien de tu información y te contactaremos cuando lo leamos.</p>

                    <div className="caja-formulario">
                        <h3>¿Quieres trabajar con nosotros?</h3>
                        <form className="form-contacto" method="post" onSubmit={handleSubmit}>
                            <label htmlFor="nombre">Nombre</label>
                            <input
                                name="nombre"
                                placeholder="Nombre*"
                                required
                                value={nombre}
                                onChange={e => setNombre(e.target.value)}
                            />

                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                required
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />

                            <label htmlFor="telefono">Teléfono</label>
                            <input
                                type="tel"
                                name="telefono"
                                placeholder="Teléfono*"
                                maxLength="14"
                                minLength="9"
                                required
                                value={telefono}
                                onChange={e => setTelefono(e.target.value)}
                            />

                            <div>
                                <label htmlFor="curriculum" className="boton-archivo">
                                    Subir CV (doc/docx/odt/pdf)
                                    <input
                                        name="curriculum"
                                        id="curriculum"
                                        onChange={e => validarArchivo(e)}
                                        multiple={false}
                                        type='file'
                                        required
                                    />
                                </label>
                                {CV
                                    ? <span id="mensaje-archivo">CV.name <GrFormTrash onClick={setCV("")} /></span>
                                    : <span id="mensaje-archivo">Por favor sube tu CV</span>
                                }
                            </div>

                            <label htmlFor="mensaje">Mensaje</label>
                            <TextareaAutosize
                                required
                                name="mensaje"
                                id="mensaje"
                                height="300"
                                placeholder="Escribe aquí tu mensaje..."
                                value={mensaje}
                                onChange={e => setMensaje(e.target.value)}
                            />
                            <div className="caja-checkbox">
                                <input
                                    type="checkbox"
                                    id="privacidad"
                                    name="privacidad"
                                    // defaultChecked={isChecked}
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                {/* <label htmlFor="privacidad" className="check-box">
                                </label> */}
                                <label htmlFor="privacidad" className="label-check">He leído y acepto la <Link to="/politica-de-privacidad/">política de privacidad</Link></label>
                            </div>

                            <div className={`mensaje-formulario
                                ${respuesta}                              
                            `} dangerouslySetInnerHTML={{ __html: mensajeError }} />

                            <IconContext.Provider value={{ color: "#1f1f1f" }}>
                                <input
                                    type="submit"
                                    value="Enviar"
                                    className="boton-enviar"
                                    disabled={isChecked && valido ? false : true}
                                />
                            </IconContext.Provider>
                            <p><small className="texto-recaptcha">Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy">política de privacidad</a> y los <a href="https://policies.google.com/terms">términos del servicio</a> de Google.</small></p>
                        </form>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default Empleo;
